export function filterMenuId (id) {
  switch (id) {
    case 1:
      return '简版财务报表'
    case 2:
      return '财报健康度'
    case 3:
      return '标准财务分析'
    case 4:
      return '行业特色数据'
    case 5:
      return '对标分析'
    default:
      return ''
  }
}

export function filterDate (date) {
  return date.substring(0, 10) + ' ' + date.substring(11, 20)
}

<template>
  <div class="dialog-wrap" @click.self="onClose">
    <div class="main">
      <div class="row">
        <div class="title">{{title}}</div>
        <img class="close" src="~@/assets/img/dialog/dialog_close_prev.png" @click="onClose"/>
      </div>
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  },
  methods: {
    onClose () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.dialog-wrap{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, .8);
  z-index: 999;
}
.main{
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #444;
}
.row{
  display: flex;
  align-items: center;
  box-shadow: 0 1px 0 2px #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 30px 0;
  .title{
    margin-left: 30px;
    flex: 1;
    font-size: 30px;
  }
  .close {
    width: 30px;
    margin-right: 30px;
  }
}
.content{
  padding: 30px;
}
</style>


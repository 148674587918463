<template>
  <div class="pl-4 pr-4" style="min-height: 750px">
    <div class="bgc_w pt-4 pb-4">
      <div class="title">
        <img
          src="@/assets/img/head/icon.png"
          style="margin-bottom: 5px; margin-right: 5px"
        />
        查询记录
      </div>
      <div class="mt-4">
        <div style="display: flex; padding-bottom: 20px">
          <div
            style="border-top-left-radius: 4px; border-bottom-left-radius: 4px"
            class="botton-radio"
            :class="{ 'active-radio': type === 'all' }"
            @click="radioClick(0)"
          >
            所有
          </div>
          <div
            class="botton-radio"
            :class="{ 'active-radio': type === 'public' }"
            @click="radioClick(1)"
          >
            上市企业
          </div>
          <div
            style="
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            "
            class="botton-radio"
            :class="{ 'active-radio': type === 'private' }"
            @click="radioClick(2)"
          >
            自定义企业
          </div>
          <!-- <label for="allRecord" class="mr-4">
              <input
                value="all"
                v-model="type"
                type="radio"
                id="allRecord"
                name="find"
                class="cursor_pionter"
                style="width: 16px; height: 16px"
              />
              所有
            </label>
            <label for="public" class="mr-4">
              <input
                value="public"
                v-model="type"
                type="radio"
                id="public"
                name="find"
                class="cursor_pionter"
                style="width: 16px; height: 16px"
              />
              公开数据
            </label>
            <label for="myPersonal" class="mr-4">
              <input
                value="private"
                v-model="type"
                type="radio"
                id="myPersonal"
                name="find"
                class="cursor_pionter"
                style="width: 16px; height: 16px"
              />
              自定义报表
            </label> -->
        </div>
      </div>
      <el-table
        stripe
        style="width: 100%"
        :data="recs"
        :maxHeight="tableContentHeight"
        :header-cell-style="{ background: '#e9f3ff', color: '#333' }"
        :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <el-table-column prop="name" label="分析公司名称"> </el-table-column>
        <el-table-column prop="menu_name" label="分析菜单" width="120">
        </el-table-column>
        <el-table-column prop="parsing_time" label="分析时间" width="120">
        </el-table-column>
        <el-table-column prop="report_time" label="报告日期" width="120">
        </el-table-column>
        <el-table-column prop="industry_type" label="行业分类选择">
        </el-table-column>
        <el-table-column prop="consume_point" label="扣减次数" width="120">
        </el-table-column>
        <el-table-column prop="date" sortable label="查询时间" width="180">
        </el-table-column>
        <el-table-column prop="ip_addr" label="登录IP" width="120">
        </el-table-column>
      </el-table>

      <div style="margin-top: 20px; text-align: right" v-if="showTable">
        <v-pagination
          :total="total"
          :pageSize="pageSize"
          :pageIndex="pageNum"
          @page-change="nextPage"
          @page-size-change="pageSizeChange"
          size="middle"
        ></v-pagination>
      </div>
    </div>
    <i-dialog
      v-if="showDetails"
      :title="details.name"
      @close="showDetails = false"
    >
      <div class="header" ref="detailsHeader">
        <span class="th">功能</span>
        <span class="th">行业</span>
        <span class="th">时间</span>
      </div>
      <div class="body">
        <div
          v-for="(item, index) in details.recs"
          :key="index"
          class="item"
          ref="detailsItem"
        >
          <span>{{ item.menu_id_id | filterMenuId }}</span>
          <span>{{ item.industry_type }}</span>
          <span>{{ item.create_time | filterDate }}</span>
        </div>
      </div>
    </i-dialog>
  </div>
</template>

<script>
import { userActivityInfos, userActivityExtra } from "@/api";
import IDialog from "@/components/IDialog";
import { filterMenuId, filterDate } from "@/filters";
export default {
  components: {
    IDialog,
    Extend: require("@/components/liu_table/render").default,
  },
  filters: {
    filterMenuId,
    filterDate,
  },
  data() {
    return {
      type: "all",
      pageSize: 10,
      pageNum: 1,
      total: 0,
      tableContentHeight:
        window.innerHeight -
        60 -
        50 -
        32 -
        20 -
        36 -
        24 -
        20 -
        10 -
        20 -
        20 -
        10 -
        10 -
        25,
      recs: [],
      details: {
        recs: [],
      },
      showDetails: false,
      showTable: true,
    };
  },
  mounted() {
    this.queryRecs();
  },
  watch: {
    showDetails(val) {
      if (!val) {
        this.details.recs = [];
      }
    },
    type() {
      this.pageNum = 1;
      this.queryRecs();
    },
  },
  methods: {
    radioClick(val) {
      if (val === 0) {
        this.type = "all";
      } else if (val === 1) {
        this.type = "public";
      } else {
        this.type = "private";
      }
    },
    renderTime(date) {
      let dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
    },
    queryRecs() {
      this.$loadingShow();
      userActivityInfos({
        pagesize: this.pageSize,
        pagenumber: this.pageNum,
        type: this.type,
      })
        .then((resp) => {
          resp.data.data.map((item) => {
            item.date = this.renderTime(item.date);
          });
          this.recs = resp.data.data;
          this.total = resp.data.total;
          this.$loadingHide();
        })
        .catch((err) => {
          console.error("userActivityInfos", err);
          this.showTable = false;
          this.recs = [];
          // this.$tip({
          //   type: "error",
          //   text: "用户登录状态异常，请关闭页面重新登录！",
          // });
          this.$loadingHide();
        });
    },
    pageSizeChange(newPageSize) {
      this.pageSize = newPageSize;
      this.pageNum = 1;
      this.nextPage(this.pageNum);
    },
    nextPage(pageNum) {
      this.pageNum = pageNum;
      if (this.pageNum >= this.total) return;
      this.queryRecs();
    },
  },
};
</script>

<style scoped lang="less">
.title {
  font-size: 20px;
  font-weight: 550;
  background: #fff;
}
.text-center {
  display: flex;
  justify-content: flex-end;
}
@import "../../assets/css/table/defineTable.css";
.botton-radio {
  padding: 8px 30px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #d7d7d7;
  text-align: center;
  font-size: 14px;
  color: #666666;
  line-height: 22px;
  cursor: pointer;
}
.active-radio {
  border: 1px solid #1989fa;
  color: #fff;
  background-color: #1989fa;
}
table td,
.table th {
  border-top: 0 !important;
}
tbody tr:nth-of-type(2n) {
  background-color: #eff3f7;
}
tbody tr:last-child {
  border-bottom: 1px solid #e0e4ea;
}

.container {
  padding: 0 15px;
}
.option {
  border-color: #9a9a9a;
  background: #ffffff;
  height: 30px;
  color: #4d4d4d;
}
.search-border {
  border: 1px solid #9a9a9a;
}
.s-icon {
  margin-left: 20px;
  height: 20px;
}
.check > i {
  margin-right: 10px;
}

// dialog
.header {
  width: 600px;
  display: flex;
  color: #000;
  padding: 10px;
  font-weight: bold;
  .th {
    width: 200px;
  }
}
.body {
  max-height: 200px;
  overflow: auto;
  width: 600px;
  color: #999;
}
.item {
  display: flex;
  padding: 10px;
  span {
    width: 200px;
  }
}
</style>
